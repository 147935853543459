import React from "react"
import { SocialIcon } from 'react-social-icons';

class SocialLinks extends React.Component {
  render() {
    return (
      <div className="social-links">
        <SocialIcon borderColor="rgba(0,0,0,0.25)" borderWidth="0" borderStyle="solid" icon="twitter" iconColor="rgba(255,255,255,1)" backgroundColor="rgba(6,160,116,1)" iconSize="6" roundness="33%" url="https://twitter.com/DeFi_LaB" size="22" />
        <SocialIcon borderColor="rgba(0,0,0,0.25)" borderWidth="0" borderStyle="solid" icon="youtube" iconColor="rgba(255,255,255,1)" backgroundColor="rgba(6,160,116,1)" iconSize="6" roundness="33%" url="https://www.youtube.com/c/DeFiLabDAO" size="22" />
        <SocialIcon borderColor="rgba(0,0,0,0.25)" borderWidth="0" borderStyle="solid" icon="linkedin" iconColor="rgba(255,255,255,1)" backgroundColor="rgba(6,160,116,1)" iconSize="6" roundness="33%" url="https://www.linkedin.com/company/defilab/" size="22" />
        <SocialIcon borderColor="rgba(0,0,0,0.25)" borderWidth="0" borderStyle="solid" icon="send" iconColor="rgba(255,255,255,1)" backgroundColor="rgba(6,160,116,1)" iconSize="6" roundness="33%" url="https://t.me/DeFi_Lab" size="22" />


      </div>
    )
  }
}

export default SocialLinks
