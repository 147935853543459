import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"


const Logo = (props) => {

  console.log(props.img.childImageSharp.fluid)
  const Logo = props.img ? props.img.childImageSharp.fluid : ""

  return (
    <div className="site-logo">
      <Link to="/">
        <Img
          fluid={Logo}
        />
      </Link>
    </div>
  )
}

export default Logo