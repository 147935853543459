import React from "react"
import CookieConsent from "react-cookie-consent"

const Cookies = () => (
  <CookieConsent
    enableDeclineButton
    location="bottom"
    buttonText="Aceptar"
    declineButtonText="Rechazar"
    cookieName="gatsby-plugin-google-analytics"
    expires={150}
    acceptOnScroll={true}
    style={{fontSize: "15px"}}
    buttonStyle={{ background: "#CCCCCC", fontSize: "15px" }}
    declineButtonStyle={{ background: "#666666", fontSize: "15px" , color:"#000000  "}}
  >
    Utilizamos cookies de terceros para analizar y mejorar nuestros servicios, elaborar información estadística, analizar sus hábitos de navegación. SI CONTINÚA NAVEGANDO, consideramos que ACEPTA SU USO. También puede RECHAZAR la instalación de cookies u obtener más información
  </CookieConsent>
)

export default Cookies
