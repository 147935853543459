import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet"

import Header from "./header"
import Logo from "./logo"
import Navigation from "./navigation";
import Cookies from "./cookies"

import "../assets/scss/style.scss"
import Footer from "./footer";
import SocialLinks from "./social-links";

const query = graphql`
query LayoutQuery {
  site {
    siteMetadata {
      siteTitle: title
    }
  }
  file(relativePath: { eq: "horizontal-logo.png" }) {
    childImageSharp {
      fluid(maxWidth: 125, maxHeight: 125) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`


const Layout = ({ children, className }) => {

  const { file } = useStaticQuery(query)

  return (
    <div className="primary-container">
      <Helmet>
        <meta http-equiv="Expires" content="0" />
        <meta http-equiv="Last-Modified" content="0" />
        <meta http-equiv="Cache-Control" content="no-cache, mustrevalidate" />
        <meta http-equiv="Pragma" content="no-cache" />
      </Helmet>
      <Header>
        <Logo img={file} />
        <Navigation />
      </Header>
      <SocialLinks />
      <main className={"container " + className}>
        {children}
      </main>
      <Cookies />
      <Footer />
    </div>
  )
}

export default Layout

