import React from "react"
import { Link } from "gatsby"
import { RiHeart2Line } from "react-icons/ri";

const Footer = () => (
  <footer className="site-footer">
    <div className="container">
      <p>Toda la información de esta web tiene caracter divulgativo y en NINGÚN caso se puede tomar como una recomendación de inversión.</p>
      <p><Link to="/cookies">Cookies</Link></p>
      <p>Desarrollado por <Link to="https://twitter.com/IAlberquilla" target="_blank">Iván Alberquilla</Link></p>
    </div>
  </footer>
)

export default Footer